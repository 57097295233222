<label
  [for]="inputId"
  [ngClass]="displayAsButton ? 'button-upload form-label' : 'form-label'"
>
  <i class="fas fa-file-upload" *ngIf="displayAsButton"></i>{{ labelDesc }}
  <span class="text-danger" *ngIf="required">*</span>
</label>
<input
  type="file"
  [name]="name"
  [hidden]="displayAsButton"
  [ngClass]="required ? 'form-control required' : 'form-control'"
  [(ngModel)]="file"
  [id]="inputId"
  [accept]="allowedFileTypes ? allowedFileTypes.join(',') : ''"
  [placeholder]="placeholder"
  (change)="uploadFile($event)"
/>
