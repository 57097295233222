import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import {HttpResponseModel} from "../models/http-response.model";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public currentUser: Observable<User> = this.currentUserSubject.asObservable();

  constructor(private http: HttpClient, private router: Router){
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string): Observable<HttpResponseModel<User>> {
    return this.http
      .post<HttpResponseModel<User>>(`${environment.apiUrl}/auth/login/`, {
        username,
        password,
      })
      .pipe(
        map((res: HttpResponseModel<User>) => {
          if(res.statusCode === 200){
            localStorage.setItem('currentUser', JSON.stringify(res.data))
            this.currentUserSubject.next(res.data);
          }
          return res;
        })
      );
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    return of({ success: true });
  }

  getAll(){
    return this.http.get<User[]>(
      `${environment.apiUrl}/users/`
    );
  }

  refreshAccessToken(refreshToken: string){
    return this.http.post(`${environment.apiUrl}/auth/refresh-token`, {token: refreshToken})
  }
}
