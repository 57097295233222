import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-file-upload-input',
  templateUrl: './file-upload-input.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadInputComponent),
      multi: true,
    },
  ],
})
export class FileUploadInputComponent implements ControlValueAccessor {
  @Input() labelDesc = 'Upload File';
  @Input() placeholder = 'Upload file';
  @Input() inputId = 'fileupload';
  @Input() displayAsButton = false;
  @Input() required = true;
  @Input() disabled = false;
  @Input() errorMessageAsHTML = 'File required <em>(.pdf, .doc, .docx, .xlsx)</em>';
  @Input() allowedFileTypes!: string[];
  @Input() name: string;

  //@Output() fileInputUpdated: EventEmitter<File> = new EventEmitter();

  file!: File;

  _onChange(event: any) {
    return;
  }

  _onTouch(event: any) {
    return;
  }


  writeValue(value: any) {
    return;
  }

  registerOnChange(fn: any): void {
      this._onChange = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  uploadFile(event: Event) {
    const fileList:FileList | null = (event.target as HTMLInputElement).files;
    if(!(fileList && (fileList.length > 0))) {
      this._onChange(null);
      this._onTouch(null);
      return;
    };

    const file: File = fileList[0];
    this._onChange(file);
    this._onTouch(file);
   
  }
  /**
   * 
   * @param file 
   * @depprecated
   */
  convertFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

}
