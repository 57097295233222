import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
const bootstrapModules = [NgbModule];

@NgModule({
  declarations: [],
  imports: [bootstrapModules],
  exports: [bootstrapModules],
})
export class BootstrapModule {}
