import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthService } from '../services/auth.service';
import { ERoles } from '../models/roles-enum';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const roles = route.data.role as ERoles[]
    if (this.authService.currentUserValue && roles.includes(this.authService.currentUserValue.role as unknown as ERoles)) {
      return true;
    }
    this.router.navigate(['/auth/signin'], { queryParams: { returnUrl: state.url }});
    return false;
  }
}
