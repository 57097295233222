import { AuthService } from "../services/auth.service";
import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (
          [401, 403].includes(err.status) &&
          this.authenticationService.currentUserValue &&
          err.url !== `${environment.apiUrl}/auth/refresh-token`
        ) {
          let currentUser = JSON.parse(localStorage.getItem("currentUser"));
          if (currentUser && currentUser.refresh_token) {
            return this.authenticationService
              .refreshAccessToken(currentUser.refresh_token)
              .pipe(
                switchMap((response: any) => {
                  currentUser.refresh_token = response.data.refresh_token;
                  currentUser.token = response.data.access_token;
                  localStorage.setItem(
                    "currentUser",
                    JSON.stringify(currentUser)
                  );
                  request = request.clone({
                    setHeaders: {
                      Authorization: `Bearer ${response.access_token}`,
                    },
                  });

                  return next.handle(request);
                }),
                catchError((refreshError) => {
                  this.authenticationService.logout();
                  location.reload();
                  return of(refreshError);
                })
              );
          } else {
            // auto logout if 401 | 403 response returned from api
            this.authenticationService.logout();
            location.reload();
          }
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
