import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {

  calendarYear: number

  constructor() { 
    
  }

  ngOnInit(): void {
    var d = new Date();
    this.calendarYear = d.getFullYear();
  }

  

}
