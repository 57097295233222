import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { ERoles } from './core/models/roles-enum';
const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      role: [ERoles.SCHOOL_ADMIN, ERoles.SUPER_ADMIN]
    },
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () =>
            import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'manage',
        loadChildren: () =>
            import('./modules/modules.module').then((m) => m.ModulesModule),
      },
      {
        path: 'finance',
        loadChildren: () =>
            import('./modules/finance/finance.module').then(
                (m) => m.FinanceModule
            ),
      }
    ],
  },

  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () =>
        import('./authentication/authentication.module').then(
            (m) => m.AuthenticationModule
        ),
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
