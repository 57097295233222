import { ERoles } from 'src/app/core/models/roles-enum';
import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard/admin',
    title: 'Dashboard',
    moduleName: 'dashboard',
    iconType: 'feather',
    icon: 'monitor',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
    ],
    roles: [ERoles.SCHOOL_ADMIN, ERoles.SUPER_ADMIN]
  },
  {
    path: 'manage/students',
    title: 'Students',
    moduleName: 'module',
    iconType: 'feather',
    icon: 'user-check',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
    ],
    roles: [ERoles.SCHOOL_ADMIN, ERoles.SUPER_ADMIN]
  },
  {
    path: 'finance',
    title: 'Finance',
    moduleName: 'finance',
    iconType: 'feather',
    icon: 'dollar-sign',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      {
        path: 'finance/fee-structure',
        title: 'Fee Structure',
        moduleName: 'finance',
        iconType: 'feather',
        icon: 'user-plus',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        roles: [ERoles.SCHOOL_ADMIN, ERoles.SUPER_ADMIN]
      },
      {
        path: 'finance/fees',
        title: 'Fees Management',
        moduleName: 'finance',
        iconType: 'feather',
        icon: 'user',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        roles: [ERoles.SCHOOL_ADMIN, ERoles.SUPER_ADMIN]
      },
      {
        path: 'finance/income',
        title: 'Income',
        moduleName: 'finance',
        iconType: 'feather',
        icon: 'book',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        roles: [ERoles.SCHOOL_ADMIN, ERoles.SUPER_ADMIN]
      },
    ],
    roles: [ERoles.SCHOOL_ADMIN, ERoles.SUPER_ADMIN]
  },
  {
    path: 'manage/users',
    title: 'Users',
    moduleName: 'module',
    iconType: 'feather',
    icon: 'users',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
    ],
    roles: [ERoles.SUPER_ADMIN]
  },
  {
    path: 'manage/schools',
    title: 'Schools',
    moduleName: 'module',
    iconType: 'feather',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
    ],
    roles: [ERoles.SUPER_ADMIN]
  },
  {
    path: '/manage',
    title: 'Manage',
    moduleName: 'manage',
    iconType: 'feather',
    icon: 'file-text',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      {
        path: '/manage/class',
        title: 'Classes',
        moduleName: 'manage',
        iconType: 'feather',
        icon: 'user-plus',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        roles: [ERoles.SCHOOL_ADMIN, ERoles.SUPER_ADMIN]
      },
      {
        path: '/manage/teachers',
        title: 'Teachers',
        moduleName: 'manage',
        iconType: 'feather',
        icon: 'user',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        roles: [ERoles.SCHOOL_ADMIN, ERoles.SUPER_ADMIN]
      },
      {
        path: '/manage/terms',
        title: 'Terms',
        moduleName: 'manage',
        iconType: 'feather',
        icon: 'book',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        roles: [ERoles.SCHOOL_ADMIN, ERoles.SUPER_ADMIN]
      },
    ],
    roles: [ERoles.SCHOOL_ADMIN, ERoles.SUPER_ADMIN]
  },
  // {
  //   path: '/logout',
  //   title: 'Logout',
  //   moduleName: 'authentication',
  //   iconType: 'feather',
  //   icon: 'log-out',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: [
  //   ],
  //   roles: [ERoles.SCHOOL_ADMIN, ERoles.SUPER_ADMIN]
  // },
];
