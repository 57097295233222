import { PageLoaderComponent } from "./page-loader/page-loader.component";
import { RightSidebarComponent } from "./right-sidebar/right-sidebar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { HeaderComponent } from "./header/header.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthLayoutComponent } from "./app-layout/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./app-layout/main-layout/main-layout.component";
import { FooterComponent } from "./footer/footer.component";
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";
import { HttpClientModule } from "@angular/common/http";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "../shared/shared.module";
import { CoreModule } from "../core/core.module";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

@NgModule({
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    NgbModule
  ],
  declarations: [
    AuthLayoutComponent,
    MainLayoutComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    RightSidebarComponent,
    PageLoaderComponent,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  exports: [
    AuthLayoutComponent,
    MainLayoutComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    RightSidebarComponent,
    PageLoaderComponent
  ]
})
export class LayoutModule {}
