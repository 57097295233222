<nav class="navbar navbar-expand-lg flex items-center main-navbar sticky">
    <div class="form-inline me-auto">
        <ul class="navbar-nav msr-3">
            <li>
                <a data-toggle="sidebar" class="nav-link nav-link-lg
									collapse-btn" (click)="mobileMenuSidebarOpen($event,'overlay-open')">
                    <i-feather name="menu"></i-feather>
                </a>
            </li>
            <li>
                <form class="form-inline me-auto">
                    <div class="search-element d-flex">
                        <input class="form-control" type="search" placeholder="Search" aria-label="Search" data-width="200">
                        <button class="btn" type="submit">
              <i class="fas fa-search"></i>
            </button>
                    </div>
                </form>
            </li>
        </ul>
    </div>
    <ul class="navbar-nav navbar-right flex items-center">
        <li>
            <a class="nav-link nav-link-lg
      collapse-btn flex items-center justify-center" (click)="callFullscreen()">
                <i-feather name="maximize"></i-feather>
            </a>
        </li>
        <li ngbDropdown>
            <a ngbDropdownToggle data-toggle="dropdown" class="nav-link dropdown-toggle nav-link-lg nav-link-user flex items-center">
                <img alt="image" src="assets/images/sidebar-profile.png" class="user-img-radious-style collapse-btn"> <span class="d-sm-none d-lg-inline-block"></span></a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right header-dropdown pullDown">
                <div class="dropdown-title">Hello {{ user.first_name }}!</div>
                <a routerLink="manage/user/profile" class="dropdown-item has-icon"> <i class="far
              fa-user"></i> Profile
                </a>
                <!--<a routerLink="/email/inbox" class="dropdown-item has-icon"> <i class="far fa-envelope"></i> Email
                </a>-->
                <a href="" onClick="return false;" class="dropdown-item has-icon"> <i class="fas fa-cog"></i> Settings
                </a>
                <div class="dropdown-divider"></div>
                <a href="javascript:void(0);" class="dropdown-item has-icon text-danger" (click)="logout()"> <i class="fas fa-sign-out-alt"></i> Logout
                </a>
            </div>
        </li>
    </ul>
</nav>